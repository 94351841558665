export const GET_CUSTOMERS_LIST = "GET_CUSTOMERS_LIST";
export const GET_CUSTOMER_DETAIL = "GET_CUSTOMER_DETAIL";
export const NULLIFY_CUSTOMER_DETAIL = "NULLIFY_CUSTOMER_DETAIL";
export const GET_CUSTOMER_QUOTES = "GET_CUSTOMER_QUOTES";
export const GET_CUSTOMERS_REPORTS = "GET_CUSTOMERS_REPORTS";
export const NULLIFY_CUSTOMER_QUOTES = "NULLIFY_CUSTOMER_QUOTES";
export const NULLIFY_CUSTOMER_REPORTS = "NULLIFY_CUSTOMER_REPORTS";
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const EDIT_CUSTOMER_DETAILS = "EDIT_CUSTOMER_DETAILS";
export const INCREASE_CUSTOMER_QUOTES = "INCREASE_CUSTOMER_QUOTES";
export const GET_CUSTOMER_ADDRESS = "GET_CUSTOMER_ADDRESS";
export const DECREASE_QUOTE_REQUESTS = "DECREASE_QUOTE_REQUESTS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const ADD_CUSTOMER_ADDRESS = "ADD_CUSTOMER_ADDRESS";

