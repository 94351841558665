export const GET_HOME_REPORTS = "GET_HOME_REPORTS";
export const GET_REPORT_DETAIL = "GET_REPORT_DETAIL";
export const NULLIFY_CURRENT_REPORT = "NULLIFY_CURRENT_REPORT";
export const GET_REPORT_COMMENTS = "GET_REPORT_COMMENTS";
export const ADD_NEW_COMMENT = "ADD_NEW_COMMENT";
export const GET_REPORT_DOCUMENTS = "GET_REPORT_DOCUMENTS";
export const UPLOAD_REPORT_DOCUMENT = "UPLOAD_REPORT_DOCUMENT";
export const SET_SURVEY_DATE = "SET_SURVEY_DATE";
export const ASSIGN_STAFF = "ASSIGN_STAFF";
export const CHANGE_REPORT_STATUS = "CHANGE_REPORT_STATUS";
export const DELETE_HOME_REPORT = "DELETE_HOME_REPORT";
